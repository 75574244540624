import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        box-sizing: border-box;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;
